import { template as template_b9934f2c1c45444eb632fdd0c544fc18 } from "@ember/template-compiler";
const WelcomeHeader = template_b9934f2c1c45444eb632fdd0c544fc18(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
