import { template as template_951014844d0d4292a4bb0a840b91a947 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_951014844d0d4292a4bb0a840b91a947(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
