import { template as template_7532274bca6e43b2b8458eb6cdfa030d } from "@ember/template-compiler";
const FKControlMenuContainer = template_7532274bca6e43b2b8458eb6cdfa030d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
