import { template as template_4108640e4f044761a69eff379abde20c } from "@ember/template-compiler";
const FKLabel = template_4108640e4f044761a69eff379abde20c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
